class StickyHeader extends HTMLElement {
  constructor() {
    super();

    this.navBackdrop = document.querySelector("[data-nav-backdrop]");
    this.header = this.closest(".header-section");
    this.desktopNav = this.querySelector(".header__desktop-nav");
    this.navBackground = this.querySelector(".header__nav-background");
    this.headerLevel1Links = this.querySelectorAll("[data-nav-level-1]");
    this.dropdownToggles = this.querySelectorAll("[data-menu-toggle]");
    this.dropdownMenus = this.querySelectorAll("[data-dropdown-menu]");
    this.activeToggle = null;
    this.hasActiveDropdown = false;

    this.mobileNavToggle = this.querySelector("[data-mobile-nav-toggle]");
    this.mobileNav = this.querySelector("[data-mobile-nav]");
    this.mobileNavIsActive = false;
  }

  connectedCallback() {
    window.addEventListener("scroll", this.updateOnScroll.bind(this));
    this.watchHeaderLevel1Links();
    this.handleDropdownMenus();
    this.mobileNavToggle.addEventListener(
      "click",
      this.toggleMobileNav.bind(this),
    );
    this.navBackdrop.addEventListener("click", this.resetNav.bind(this));
    this.navBackdrop.addEventListener("click", () => {
      if (window.appState.nav) {
        this.resetMobileNav();
      }
    });

    document.addEventListener("toggleCart", this.resetNav.bind(this));
    document.addEventListener("toggleCart", () => this.resetMobileNav(false));
    document.addEventListener("toggleSearch", this.resetNav.bind(this));
    document.addEventListener("toggleSearch", () => this.resetMobileNav(false));
    document.addEventListener("keydown", this.handleKeyDown.bind(this));

    this.desktopNav.addEventListener(
      "mouseover",
      this.updateNavBackground.bind(this),
    );

    this.desktopNav.addEventListener(
      "mouseleave",
      this.resetNavBackground.bind(this),
    );

    const mediaQuery = window.matchMedia("(min-width: 800px)");
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener("change", this.handleResize.bind(this));
    } else {
      mediaQuery.addListener(this.handleResize.bind(this));
    }
  }

  handleResize() {
    this.resetNav();
    this.resetMobileNav();
  }

  updateOnScroll() {
    if (window.scrollY > 100) {
      this.header.classList.add("is-scrolled");
    } else {
      this.header.classList.remove("is-scrolled");
    }
  }

  updateNavBackground(e) {
    let activeElement = e.target.closest("[data-nav-level-1]");

    if (!activeElement) return;

    this.navBackground.style.width = `${activeElement.clientWidth}px`;
    this.navBackground.style.transform = `translateX(${activeElement.parentElement.offsetLeft}px)`;

    this.headerLevel1Links.forEach((el) => {
      el.classList.remove("is-red");
    });
  }

  resetNavBackground() {
    // If a dropdown is active, reset the navBackground to the active dropdown toggle
    if (this.hasActiveDropdown) {
      this.navBackground.style.width = `${this.activeToggle.clientWidth}px`;
      this.navBackground.style.transform = `translateX(${this.activeToggle.parentElement.offsetLeft}px)`;
      this.navBackground.style.opacity = 1;
      this.activeToggle.classList.add("is-red");
    }
  }

  watchHeaderLevel1Links() {
    this.headerLevel1Links.forEach((el) => {
      el.addEventListener("mouseenter", this.updateNavBackground.bind(this));
      el.addEventListener("focus", this.updateNavBackground.bind(this));
    });
  }

  handleDropdownMenus() {
    this.dropdownToggles.forEach((toggle) => {
      toggle.addEventListener("click", (e) => {
        e.preventDefault();

        const targetMenu = this.querySelector(
          `[data-dropdown-menu="${toggle.dataset.menuToggle}"]`,
        );

        // Close any open dropdown menus
        this.dropdownMenus.forEach((menu) => {
          if (menu !== targetMenu && menu.classList.contains("is-shown")) {
            menu.classList.remove("is-shown");
            menu.setAttribute("aria-expanded", "false");
            this.querySelector(
              `[data-menu-toggle="${menu.dataset.dropdownMenu}"]`,
            ).classList.remove("is-active");
          }
        });

        // Toggle the target dropdown menu
        if (targetMenu.classList.contains("is-shown")) {
          targetMenu.classList.remove("is-shown");
          toggle.classList.remove("is-active");
          targetMenu.setAttribute("aria-expanded", "false");
          document.body.classList.remove("dropdown-active");

          this.activeToggle = null;
          this.hasActiveDropdown = false;
          this.headerLevel1Links.forEach((el) => {
            el.classList.remove("is-red");
          });
          this.navBackground.style.removeProperty("opacity");

          document.dispatchEvent(
            new CustomEvent("closedDropdown", { bubbles: true }),
          );
        } else {
          targetMenu.classList.add("is-shown");
          toggle.classList.add("is-active");
          targetMenu.setAttribute("aria-expanded", "true");
          document.body.classList.add("dropdown-active");

          this.headerLevel1Links.forEach((el) => {
            el.classList.remove("is-red");
          });

          this.activeToggle = toggle;
          this.hasActiveDropdown = true;

          // Focus the first focusable element in the dropdown
          const focusableElements = targetMenu.querySelectorAll(
            'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
          );
          if (focusableElements.length > 0) {
            focusableElements[0].focus();
          }

          document.dispatchEvent(
            new CustomEvent("activeDropdown", { bubbles: true }),
          );
        }
      });
    });
  }

  resetNav() {
    this.dropdownMenus.forEach((menu) => {
      if (menu.classList.contains("is-shown")) {
        menu.classList.remove("is-shown");
        menu.setAttribute("aria-expanded", "false");
        this.querySelector(
          `[data-menu-toggle="${menu.dataset.dropdownMenu}"]`,
        ).classList.remove("is-active");
      }
    });
    document.body.classList.remove("dropdown-active");
    this.activeToggle = null;
    this.hasActiveDropdown = false;
    this.navBackground.style.removeProperty("opacity");
    this.headerLevel1Links.forEach((el) => {
      el.classList.remove("is-red");
    });

    document.dispatchEvent(
      new CustomEvent("closedDropdown", { bubbles: true }),
    );
  }

  resetNavOnResize() {
    if (window.matchMedia("(max-width: 800px)").matches) {
      this.resetNav();
    } else {
      this.resetMobileNav();
    }
  }

  openMobileNav() {
    this.lockScroll();
    this.mobileNavIsActive = true;
    window.appState.nav = true;
    this.mobileNav.setAttribute("aria-expanded", "true");
    this.navBackdrop.classList.add("is-shown");
    this.mobileNav.classList.add("is-shown");
    document.body.classList.add("menu-active");
    // Dispatch an event to close other open components
    const event = new Event("toggleMobileNav", { bubbles: true });
    document.dispatchEvent(event);
    // Dispatch an event to pause autoplaying swiper
    document.dispatchEvent(
      new CustomEvent("activeDropdown", { bubbles: true }),
    );
  }

  resetMobileNav(includeUnlockScroll = true) {
    this.mobileNavIsActive = false;
    window.appState.nav = false;
    this.mobileNav.setAttribute("aria-expanded", "false");
    this.mobileNav.classList.remove("is-shown");
    this.navBackdrop.classList.remove("is-shown");
    document.body.classList.remove("menu-active");

    document.dispatchEvent(
      new CustomEvent("closedDropdown", { bubbles: true }),
    );
    if (includeUnlockScroll) this.unlockScroll();
  }

  toggleMobileNav() {
    if (this.mobileNavIsActive) {
      this.resetMobileNav();
    } else {
      this.openMobileNav();
    }
  }

  lockScroll() {
    if (document.body.style.position === "fixed") {
      return;
    }
    const scrollY = window.scrollY;
    document.documentElement.style.setProperty("--scroll-y", `${scrollY}px`);
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.top = `-${scrollY}px`;
  }

  unlockScroll() {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.width = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }

  handleKeyDown(event) {
    // Check if the pressed key was the escape key
    if (event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) {
      // If the mobile nav or a dropdown menu is active, close it
      if (this.mobileNavIsActive) {
        this.resetMobileNav();
      } else {
        this.resetNav();
      }
    }

    // Check if the pressed key was the tab key
    if (event.key === "Tab" || event.keyCode === 9) {
      // If a dropdown menu is active, trap focus within it
      const activeDropdown = Array.from(this.dropdownMenus).find((menu) =>
        menu.classList.contains("is-shown"),
      );
      if (activeDropdown) {
        this.trapFocus(event, activeDropdown);
      }

      // If the mobile nav is active, trap focus within it
      if (this.mobileNavIsActive) {
        this.trapFocus(event, this.mobileNav);
      }
    }
  }

  trapFocus(event, container) {
    const focusableElements = container.querySelectorAll(
      'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
    );
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    if (event.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        event.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        event.preventDefault();
      }
    }

    // If no element is currently focused, focus the first focusable element
    if (
      !document.activeElement ||
      !container.contains(document.activeElement)
    ) {
      firstFocusableElement.focus();
    }
  }
}

customElements.define("sticky-header", StickyHeader);
